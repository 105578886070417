body {
  margin: 0;
  background-color: #191b3d;
  font-family: "Poppins !important";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the entire header content */
  padding: 10px;
  /* height: 40px; */
  background-color: #0c0c2e;
}

.header .back-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header .main-logo {
  display: flex;
  align-items: center; /* Align the logo and text vertically */
}

.header .main-logo img {
  width: 50px;
  height: auto;
}

.header .secondary-logo {
  margin-left: 10px; /* Add some space between the logos */
  margin-top: 10px;
}

.header .secondary-logo img {
  width: 100px; /* Adjust this value as per your text logo size */
  height: 50px;
}

.pricingCard::before {
  content: " ";
  position: absolute;
  background-image: url("/public/images/Images/TransformArrow.svg");
  background-size: cover;
  background-position: 0% 50%;
  height: 20%;
  width: 30%;
  margin-right: 80px; /* Add margin-right */
}

/* Default styles for larger screens */
.pricingCard {
  position: relative;
  display: flex;
  overflow: hidden;
}

.pricingCardFemale::before {
  content: " ";
  position: absolute;
  background-image: url("/public/images/Images/TransformArrowFemale.svg");
  background-size: cover;
  background-position: 0% 50%;
  height: 20%;
  width: 30%;
  margin-right: 80px; /* Add margin-right */
}

/* Default styles for larger screens */
.pricingCardFemale {
  position: relative;
  display: flex;
  overflow: hidden;
}

/* Hide image for small screens */
@media (max-width: 924px) {
  /* .pricingCard::before {
    display: none; 
  }
  .pricingCardFemale::before {
    display: none;
  } */
}

@media screen and (min-width: 850) {
  .mobile-view-wellness-card {
    display: none;
  }
}

.wellness-profile-bg-female::before {
  content: " ";
  position: absolute;
  background-image: url("/public/images/Images/WellnessProfileBackground.svg");
  background-size: cover;
  background-position: 100% 100%; /* Align the image to the bottom right */
  height: 100%;
  width: 75%;
  right: 0; /* Move to the right */
  bottom: -3px; /* Move to the bottom */
  margin-right: -5px; /* Remove margin-right */
}

/* Default styles for larger screens */
.wellness-profile-bg-female {
  position: relative;
  display: flex;
  overflow: hidden;
}

.wellness-profile-bg-male::before {
  content: " ";
  position: absolute;
  background-image: url("/public/images/Images/WellnessProfileBackgroundMale.svg");
  background-size: cover;
  background-position: 100% 100%; /* Align the image to the bottom right */
  height: 100%;
  width: 75%;
  right: 0; /* Move to the right */
  bottom: -3px; /* Move to the bottom */
  margin-right: -5px; /* Remove margin-right */
}

/* Default styles for larger screens */
.wellness-profile-bg-male {
  position: relative;
  display: flex;
  overflow: hidden;
}

.checkmark {
  opacity: 0;
  transform: scale(0);
  transition: opacity 1s ease, transform 1s ease;
  animation: appear 1s forwards;
  /* Animate appearance */
}

.fade-out {
  opacity: 0;
  transform: scale(0);
  transition: opacity 1s ease, transform 1s ease;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
