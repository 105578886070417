:root {
  --primary-background-color: #191b3d;
  --secondary-background-color: #141432;
  --frame-background: #262846;
  --chart-background-color: #141432;
  --price-card-background: #262846;
  --selected-price-card-background: #162450;

  --primary-text-color: white;
  --text-color2: #4dd0e1;

  --border: #007aff;
  --image-border: #288fff;
  --selected-price-card-border: #036ee7;

  --selected-button: #007aff;
  --primary-button-color: #006bff;
  --selected-button-text: #ffffff;

  --progressed-color: #0150b6;
  --remaining-progress-color: #414052;

  --card-color: #141432;
  --card2-color: #191b3d;
}

.button {
  background-color: var(--primary-button-color) !important;
}

.text-color {
  color: var(--primary-text-color) !important;
}

.text-color2 {
  color: var(--text-color2) !important;
}

.background-color-secondary {
  background-color: var(--secondary-background-color);
}

.border {
  border: 0.5px solid;
  border-color: var(--border);
  border-radius: 15px;
}

.rounded-border {
  border: 1px solid;
  border-radius: 50%;
  border-color: var(--border);
}

.square-checkbox-border {
  border: 1px solid;
  border-radius: 20%;
  border-color: var(--border);
}

.secondary-border-color {
  border-color: "#ffbfe5";
}

.frame-background {
  background-color: var(--frame-background);
}

.male-selected-button {
  background-color: var(--selected-button) !important;
}

.chart-background {
  background-color: var(--chart-background-color);
}

.progressed-color {
  color: var(--progressed-color) !important;
}

.remaining-progress-color {
  color: var(--remaining-progress-color) !important;
}

.image-border {
  border: 1px solid;
  border-color: var(--image-border);
}

.card {
  background-color: var(--card-color) !important;
  border-radius: 10px;
  height: "auto";
}

.card2 {
  background-color: var(--card2-color) !important;
}

.price-card-background {
  background-color: var(--price-card-background) !important;
}

.selected-price-card {
  background-color: var(--selected-price-card-background) !important;
  border: 2px solid !important;
  border-color: var(--selected-price-card-border) !important;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// Create an animation class
.main-div-animate {
  opacity: 0;
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.4s;
}

.responsive-circle-tick-img {
  width: 100%; /* Makes the image responsive */
  height: 100%; /* Keeps the aspect ratio */
  max-width: 28px !important; /* Maximum width for all screen types */
  max-height: 28px !important; /* Maximum height for all screen types */
  object-fit: contain;
}

/* xs: Extra-small (0px and up) */
@media (max-width: 599px) {
  .responsive-circle-tick-img {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
  .notfound-img {
    max-width: 320px;
  }
}

/* sm: Small (600px and up) */
@media (min-width: 600px) and (max-width: 899px) {
  .responsive-circle-tick-img {
    width: 25px;
    height: 25px;
    min-width: 25px;
  }
  .notfound-img {
    max-width: 480px;
  }
}

/* md: Medium (900px and up) */
@media (min-width: 900px) and (max-width: 1199px) {
  .responsive-circle-tick-img {
    width: 25px;
    height: 25px;
    min-width: 25px;
  }
  .notfound-img {
    max-width: 720px;
  }
}

/* lg: Large (1200px and up) */
@media (min-width: 1200px) {
  .responsive-circle-tick-img {
    width: 28px;
    height: 28px;
    min-width: 28px;
  }
  .notfound-img {
    max-width: 720px;
  }
}

// Define keyframes for zoom-in effect
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1); // Zoom in slightly
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9); // Zoom out slightly
  }
}

// Reset back to normal size when hover is removed (not really needed with CSS transition but for clarity)
@keyframes resetZoom {
  0% {
    transform: scale(1); // Starts from the zoomed-out state
  }
  100% {
    transform: scale(1); // Resets back to original size
  }
}

// Base box styling
.box-hover-effect {
  display: inline-block;
  transition: transform 0.3s ease-in-out; // Smooth transition between scale states
}

// Apply zoom-out on hover
.box-hover-effect:hover {
  transform: scale(0.9); /* Scale down on hover */
}

/* No need for a separate reset animation */
.box-hover-effect:not(:hover) {
  transform: scale(1); /* Reset to normal size when hover ends */
}

#mainBodyDiv:hover .male-selected-button {
  background-color: #ffbbe4 !important; /* Female color */
  color: #141432 !important; /* Female text color */
  transform: scale(1.05); /* Optional: Add a slight scale effect */
  transition: background-color 0.5s, transform 0.5s; /* Smooth transition */
}

#mainBodyDiv:hover .male-selected-button[data-gender="male"] {
  background-color: #007aff !important; /* Male color */
  color: white !important; /* Male text color */
}

.responsive-image {
  width: 100%;
  height: auto;
}
